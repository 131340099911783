import axios from "@/plugins/axios";
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

export default {
  async fetchDashboardData({ commit, state }, { forceRefresh = false } = {}) {
    // if loading, do nothing
    if (state.loadingDashboard) return;

    // check if data in cache is valid
    const now = new Date().getTime();
    const isDataValid =
      state.lastUpdate && now - state.lastUpdate < CACHE_DURATION;

    // if data is valid and forceRefresh is false, do nothing
    if (isDataValid && !forceRefresh) return;

    try {
      commit("SET_LOADING_DASHBOARD", true);

      const startOfMonth = new Date(now);
      startOfMonth.setDate(1);
      const endOfMonth = new Date(
        startOfMonth.getFullYear(),
        startOfMonth.getMonth() + 1,
        0
      );

      const { data } = await axios.get(
        `${process.env.VUE_APP_STAGING}/v2/corporate/dashboard`,
        {
          params: {
            startDate: startOfMonth.toISOString().split("T")[0],
            endDate: endOfMonth.toISOString().split("T")[0],
          },
        }
      );

      commit("SET_DASHBOARD_DATA", data.data);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      commit("SET_LOADING_DASHBOARD", false);
    }
  },

  async fetchUsers({ commit }) {
    try {
      commit("SET_LOADING_USERS", true);
      const {
        data: { data },
      } = await axios.get(`${process.env.VUE_APP_STAGING}/v2/corporate/users`, {
        params: { isActive: true },
      });
      commit("SET_TOTAL_COLLABORATORS", data.users.length);
    } catch (error) {
      console.error(error);
      commit("SET_TOTAL_COLLABORATORS", 0);
    } finally {
      commit("SET_LOADING_USERS", false);
    }
  },
};
