import {
  mdiHomeOutline,
  mdiHistory,
  mdiAccountMultipleOutline,
  mdiWalletOutline,
  mdiPackageVariantClosed,
  mdiBookOpen,
  mdiKey,
  mdiCarOutline,
  mdiMapMarkerPlusOutline,
  mdiHeadset,
} from "@mdi/js";

export default [
  {
    title: "Dashboards",
    icon: mdiHomeOutline,
    to: "dashboards",
  },
  {
    subheader: "REQUESTS",
  },
  {
    title: "Running trips",
    icon: mdiCarOutline,
    to: "runningTrips",
  },
  {
    title: "Multistop",
    icon: mdiMapMarkerPlusOutline,
    to: "multiStop",
    module: "multiStop",
  },
  {
    title: "History",
    icon: mdiHistory,
    to: "history",
  },
  {
    subheader: "PARCELS",
  },
  {
    title: "Running parcels",
    icon: mdiPackageVariantClosed,
    to: "runningParcels",
    module: "parcel",
  },
  // {
  //   title: "Multiparadas ",
  //   icon: mdiMapMarkerPath,
  //   to: "parcelMultiStop",
  //   module: "parcel",
  // },
  {
    title: "History ",
    icon: mdiHistory,
    to: "parcelHistory",
    module: "parcel",
  },
  {
    subheader: "USERS",
  },
  {
    title: "Payroll",
    icon: mdiAccountMultipleOutline,
    to: "users",
  },
  {
    subheader: "FINANCIAL",
  },
  {
    title: "Balance",
    icon: mdiWalletOutline,
    to: "balance",
  },
  {
    subheader: "API",
  },
  {
    title: "Documentation",
    icon: mdiBookOpen,
    to: "ApiDocumentation",
    module: "api",
  },
  {
    title: "Credentials",
    icon: mdiKey,
    to: "ApiCredentials",
    module: "api",
  },
  {
    subheader: "HELP",
  },
  {
    title: "Support",
    icon: mdiHeadset,
    href: "https://wa.me/message/XWIMSGXEF3LHA1",
    target: "_blank",
  },
];
