import axios from "axios";

import store from "../store";
import router from "../router";

const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 120000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Accept: "*/*",
  },
});

service.defaults.timeoutErrorMessage = "Timeout error";

/**
 * Enviamos el Token en cada petición axios
 */
service.interceptors.request.use(
  (config) => {
    if (store.state.auth.token) {
      config.headers["x-access-token"] = store.state.auth.token;
      config.headers["Authorization"] = `Bearer ${store.state.auth.token}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.message === "Timeout error") {
      return Promise.reject(new Error("Timeout error"));
    }

    if (!error.response.status) {
      return Promise.reject(new Error("Network Error"));
    }

    if (error.message === "Network Error") {
      return Promise.reject(new Error("Network Error"));
    }

    const { status, data } = error.response;
    // Si no tiene un Token o está vencido, lo enviamos al formulario de Login
    if (status === 401 || data === "Invalid Token") {
      store.dispatch("auth/logout");
      if (router.history.current.name !== "auth-login")
        router.push({ name: "auth-login" });
      if (store.state.auth.lang === "es") {
        return Promise.reject(new Error("Sesión expirada"));
      } else {
        return Promise.reject(new Error("Expired session"));
      }
    }

    if (status === 403 && data === "A token is required for authentication") {
      console.log(data);
      store.dispatch("auth/logout");
      if (router.history.current.name !== "auth-login")
        router.push({ name: "auth-login" });
      if (store.state.auth.lang === "es") {
        return Promise.reject(new Error("Sesión expirada"));
      } else {
        return Promise.reject(new Error("Expired session"));
      }
    }
    error.error_code = data.error_code || undefined;
    return Promise.reject(error);
  }
);

export default service;
