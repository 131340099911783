export default {
  user: null,
  isLogged: false,
  token: null,
  _id: null,
  unique_id: null,
  email: null,
  name: null, 
  countryName: null,
  phone: null, 
  countryPhoneCode: null,
  wallet: null,
  overdraft: null,
  picture_url: null,
  pre_time: null,
  lang: 'es',
  admin_phone: "+584127156879",
  admin_email: "viajes@ridery.app",
  is_active_shipments: null,
  is_active_multi_stops: null,
  is_corporate_master: null,
  is_used_google_maps_service: null,
  is_allowed_corporate_api_partner: null,
  is_corporate_api_partner: null,
  is_allowed_corporate_shipment_api: null,
  is_assist_mode: null,
  is_allow_credits: null,
  updated_at: null,
}
