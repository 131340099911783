export default {
  SET_DASHBOARD_DATA(state, data) {
    state.dashboardData = data;
    state.lastUpdate = new Date().getTime();
    // save in localStorage
    localStorage.setItem('dashboardData', JSON.stringify(data));
    localStorage.setItem('dashboardLastUpdate', state.lastUpdate);
  },
  SET_LOADING_DASHBOARD(state, loading) {
    state.loadingDashboard = loading;
  },
  SET_LOADING_USERS(state, loading) {
    state.loadingUsers = loading;
  },
  SET_TOTAL_COLLABORATORS(state, total) {
    state.totalCollaborators = total;
  },
};
