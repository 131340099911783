export default {
  dashboardData: JSON.parse(localStorage.getItem('dashboardData')) || {
    totals: {
      trips: 0,
      shipments: 0,
    },
    details: {
      trips: [],
      shipments: [],
    },
  },
  loadingDashboard: false,
  loadingUsers: false,
  totalCollaborators: 0,
  lastUpdate: localStorage.getItem('dashboardLastUpdate') || null,
};
